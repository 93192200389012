import { createApp } from 'vue';
import store from './stores/stepVistoria';

// @ts-ignore
import VueSignaturePad from 'vue-signature-pad';

import App from './App.vue';
import router from './router';

import './style/main.scss';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueSignaturePad);

app.mount('#app');
